@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Work Sans", -apple-system, BlinkMacSystemFont, "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-d-black bg-primary-background;
}

a {
  color: inherit;
  text-decoration: none;
  font-size: 12px;
}

* {
  box-sizing: border-box;
}

body {
  top: 0 !important;
}

body .dropdownListStyle li:not(:hover) {
  background-color: transparent !important;
}

body .dropdownListStyle li {
  @apply py-1 text-sm;
}

body .dropdownRootStyle > div {
  @apply shadow-md;
}

body .active-nav {
  @apply block text-primary;
}

body .active-nav .MuiListItemIcon-root svg,
body .active-nav svg,
body .active-nav span {
  @apply text-white fill-white;
}

h2 {
  @apply text-d-black text-xl leading-8 font-semibold;
}

.body-text {
  @apply text-mute text-xs font-medium;
}

.skiptranslate {
  display: none !important;
}
